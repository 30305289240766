import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { ShimmerEffect } from '../../common/ShimmerEffect';
import { doScrollToTop, getAssetUrl, resizedImageURL, srcsetImageURL } from '../../../helpers/general';
import { getMobileDetect } from '../../../constants';
import { useAxiosSWR } from '../../../../axiosInstance';
import ShowShorts from './ShowShorts';

export default function HomeShortsContainer() {

    const containerRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);
    const md = getMobileDetect();
    const isMobile = md.mobile();
    const { data, isLoading, error, mutate } = useAxiosSWR('/v1/stories');
    const [isOpen, setIsOpen] = useState(false);
    const [activeId, setActiveId] = useState(null);

    const nextIcon = getAssetUrl("next-svg.svg");
    const prevIcon = getAssetUrl("prev-svg.svg");

    const nextSlide = () => {
        if (containerRef.current) {
            containerRef.current.scroll({
                left: containerRef.current.scrollLeft + 285,
                behavior: "smooth",
            });
        }
    };

    const prevSlide = () => {
        if (containerRef.current) {
            containerRef.current.scroll({
                left: containerRef.current.scrollLeft - 285,
                behavior: "smooth",
            });
        }
    };

    const handleScroll = () => {
        if (containerRef.current) {
            setShowLeftArrow(containerRef.current.scrollLeft > 0);
            setShowRightArrow(
                containerRef.current.scrollLeft <
                containerRef.current.scrollWidth - containerRef.current.clientWidth
            );
        }
    };

    const ArrowButton = ({ direction, onClick, icon }) => (
        <button
            className={`absolute ${direction === "left" ? "-left-[1px]" : "-right-[1px] justify-end"
                } top-1/2 transform -translate-y-1/2 live-nav flex items-center opacity-100 h-full w-[20px] md:w-[50px] z-10`}
            onClick={onClick}
            style={{
                visibility:
                    direction === "left"
                        ? showLeftArrow
                            ? "visible"
                            : "hidden"
                        : showRightArrow
                            ? "visible"
                            : "hidden",
                background: direction === 'left' ? "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)" : "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
            }}
        >
            <ShimmerEffect src={icon} alt={direction} className="w-16 hidden md:flex" />
        </button>
    );

    if (isLoading) {
        return <div className='flex justify-center items-center w-full h-screen text-xl'>Loading...</div>
    }

    if (error) {
        return (
            <div className='flex flex-col justify-center items-center w-full h-full text-xl text-center'>
                something went wrong fetching web stories
                <button onClick={mutate} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 w-fit mx-auto text-lg">
                    Try Again
                </button>
            </div>
        )
    }

    return (
        <div className='bg-white border rounded-2xl'>
            <div className='px-5 md:py-8 py-4'>
                <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center min-w-[50px]`}>
                    <span className="custom-border-left mr-3 md:mr-6"></span>
                    <h2 className='text-[16px] md:text-2xl'>Web Stories</h2>
                    <span className="custom-border-right ml-3 md:ml-6"></span>
                </div>
            </div>

            <div className="relative">
                <div ref={containerRef} onScroll={handleScroll} className="flex gap-[10px] overflow-x-auto hideScrollbar overflow-y-hidden items-stretch px-3 mb-5 md:mb-9 overflow-hidden">
                    {data && data.length > 0 ? data.map((item) => {
                        const firstMedia = item?.stories?.[0]?.media?.[0];
                        const mediaType = item?.stories?.[0]?.type;
                        const slug = item?.info?.slug ? `${item?.info?.slug}-${item.id}` : item.id;
                        return (
                            <div
                                key={item.id}
                            // onClick={() => { setIsOpen(true), setActiveId(item.id) }}
                            >
                                <Link to={`/web-stories/${slug}`} rel="noopener noreferrer" className='h-full w-full'>
                                    <div className="shorts-box w-[170px] h-[315px] md:w-[228px] md:h-[400px] relative rounded-xl md:rounded-[22px] overflow-hidden cursor-pointer">
                                        {mediaType === 'image' ? (
                                            <ShimmerEffect
                                                src={resizedImageURL(firstMedia?.url,"170x315")}
                                                srcSet={srcsetImageURL(firstMedia?.url,"170x315","228x400")}
                                                alt='shorts-thumb'
                                                className='w-full h-full rounded-xl md:rounded-[28px] object-center'
                                            />
                                        ) : (
                                            <video
                                                src={firstMedia?.url}
                                                className="w-full h-full rounded-xl md:rounded-[28px] object-cover"
                                            />
                                        )}
                                        <div className="absolute bottom-0 w-full z-10 p-2 md:p-4">
                                            <p className="text-white text-[12px] md:text-[15px] font-medium leading-tight">
                                                {item.title}
                                            </p>
                                            <div className='flex gap-1 mt-3'>
                                                {item.stories.map((media) => (
                                                    <div key={media.id} className='bg-white h-1 rounded-lg' style={{ width: `${100 / item.stories.length}%` }} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        );
                    }) : <div>No stories available</div>}
                    {data && data.length > 0 && isMobile ?
                        <Link
                            to='/web-stories'
                            onClick={doScrollToTop}
                            className='flex flex-shrink-0 justify-center items-center bg-white text-[#3A32D1] font-semibold text-xs rounded-xl h-[41px] w-[92px] my-auto'
                            style={{ boxShadow: '0px 4px 14px 0px #00000036' }}
                        >
                            See More
                        </Link>
                        : null
                    }
                </div>
                <ArrowButton direction="left" onClick={prevSlide} icon={prevIcon} />
                {data.length > 2 && (
                    <ArrowButton direction="right" onClick={nextSlide} icon={nextIcon} />
                )}
            </div>
            {!isMobile ?
                <>
                    <div className='flex items-end justify-center h-2 w-full bg-gradient-to-t from-[#e2e2e2ab] to-transparent'>
                    </div>
                    <div className='text-[#3A32D1] text-center py-3 md:text-sm text-xs font-semibold'>
                        <Link
                            key='btnSeeMoreNews2'
                            to='/web-stories'
                            onClick={doScrollToTop}
                        >
                            See More
                        </Link>
                    </div>
                </> : null
            }
            {isOpen ? <ShowShorts storyData={data} activeId={activeId} setActiveId={setActiveId} setIsOpen={setIsOpen} /> : null}
        </div>
    )
}
